import { Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

const FEATURE_FLAGS = {
    SELF_BOOKING: false,
};

export type FeatureFlag = keyof typeof FEATURE_FLAGS;

export const FeatureFlags: Record<FeatureFlag, FeatureFlag> = Object.assign(
    {},
    ...Object.keys(FEATURE_FLAGS).map(key => ({ [key]: key }))
);

@Injectable({
    providedIn: 'root',
})
export class FeatureFlagService {
    private featureFlagSignals: {
        [key in FeatureFlag]: WritableSignal<boolean>;
    } = Object.assign(
        {},
        ...Object.entries(FEATURE_FLAGS).map(([key, value]) => ({
            [key]: signal(value),
        }))
    );

    constructor() {}

    getFeatureFlag(flag: FeatureFlag): Signal<boolean> {
        return this.featureFlagSignals[flag].asReadonly();
    }

    setFeatureFlag(flag: FeatureFlag, state: boolean): void {
        this.featureFlagSignals[flag].set(state);
    }
}

interface FeatureFlagResolverData {
    featureFlag: FeatureFlag;
    value?: boolean;
}

@Injectable({ providedIn: 'root' })
export class FeatureFlagResolver {
    constructor(private featureFlagService: FeatureFlagService) {}

    resolve(route: ActivatedRouteSnapshot) {
        if (!route.data['featureFlags']) {
            return;
        }
        route.data['featureFlags'].forEach(
            ({ featureFlag, value }: FeatureFlagResolverData) => {
                if (!value) {
                    this.featureFlagService.setFeatureFlag(
                        featureFlag,
                        FEATURE_FLAGS[featureFlag]
                    );
                } else {
                    this.featureFlagService.setFeatureFlag(featureFlag, value);
                }
            }
        );
    }
}
